<template>
    <div id="fas-global-search" class="coveo-search-panel" tabindex="0"
         :class="searchPage ? 'coveo-search-panel-box' : ['search-panel']">
        <div class="CoveoAnalytics" ></div>
        <div id="header-second-row" class="container row">
            <div id="headerCoveoExternalComponents">
                <div class="CoveoSearchbox"
                     data-enable-omnibox="true"
                     data-number-of-suggestions="10"
                     data-enable-wildcards="true"
                     data-enable-query-syntax="true"
                     data-enable-search-as-you-type="false"
                     data-omnibox-timeout="300"
                     data-query-suggest-character-threshold="2"
                     data-trigger-query-on-clear="false">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CoveoService from "@/js/services/coveo.service";
    import {adobeLaunch} from "vg-fas-adobe-launch";
    import { Icon } from "@vg-faset/ui/src/components/icon";
    import { EventBus } from "@vg-faset/core";
    import EnvConfigService from "@/js/services/envConfig.service";
    import * as DOMPurify from 'dompurify';

    export default {
        name: "CoveoSearchbox",
        props: {
            content: {
                type: Object
            },
            searchPage: {
                type: Boolean
            },
            coveoConfigs :{
                type:Object
            }
        },
        components: {
            Icon
        },
        data() {
            return {
                inputBox: Object,
                searchTypeGlobal : 'global',
                searchTypeAutoSuggest: 'auto suggest',
                searchBarContent: this.content.searchutilitybar,
                omniboxResults: [],
                isFirstKeyUpInSearchBox:false,
                isGlobal:true,
                isMLSuggestion:false,
                domainSiteUrl:""
            }
        },
        methods: {
            sanitizeForXSS(unsafeQuery) {
                //This method prevents XSS
                var div = document.createElement('div');
                div.innerText = unsafeQuery;
                return div.innerHTML;
            },
            inputBoxKeyUp (data) {
                let inputBox = document.querySelector(".magic-box-input input");
                if(!this.isFirstKeyUpInSearchBox && this.searchPage){
                    EventBus.$emit("load-coveo-suggestions");
                    this.isFirstKeyUpInSearchBox = true
                }
                if(!data) return;
                let computedStyle = window.getComputedStyle(inputBox);
                if(!computedStyle) return;
                let pixelWidth = Math.round(this.getWidthOfText(inputBox.value,computedStyle.fontFamily,computedStyle.fontSize));
                let magicBoxClear = document.querySelector('.magic-box-clear');
                magicBoxClear.style.display = 'block';
                if (magicBoxClear) {
                    magicBoxClear.style.right = String('calc(100% - '+ String(pixelWidth + 45)+'px)');
                }
                this.updateReturnIcon(inputBox.value);
            },
            updateReturnIcon(queryString) {
                let searchIcon = document.querySelector(".coveo-search-button");
                if(queryString){
                    searchIcon.style.backgroundImage = "url(\"/content/dam/fas/visual-asset/iconography/return_icon_default.svg\")";
                } else {
                    searchIcon.style.backgroundImage = "url(\"/content/dam/fas/visual-asset/iconography/return_icon_disabled.svg\")";
                }
                searchIcon.style.backgroundRepeat = "no-repeat";
            },
            inputBoxKeyDown (data) {
                if (data.keyCode == 9)
                    data.preventDefault();
            },
            inputBoxBlur (data) {
                // empty on purpose
            },
            getWidthOfText (txt, fontname, fontsize) {
                if(this.getWidthOfText.c === undefined){
                    this.getWidthOfText.c=document.createElement('canvas');
                    this.getWidthOfText.ctx=this.getWidthOfText.c.getContext('2d');
                }
                let fontspec = fontsize + ' ' + fontname;
                if(this.getWidthOfText.ctx.font !== fontspec)
                    this.getWidthOfText.ctx.font = fontspec;
                return this.getWidthOfText.ctx.measureText(txt).width;
            },
            highlight(content, keywords) {
                let tempContent = content.toLowerCase();
                for (let i = 0; i < keywords.length; ++i) {
                    let index = tempContent.indexOf(keywords[i].toLowerCase());
                    if (index >= 0) {
                        tempContent.replace(keywords[i].toLowerCase(), new Array(keywords[i].length).join(' '));
                        tempContent = tempContent.substring(0, index) + "                                            " + tempContent.substring(index, index + keywords[i].length) + "       " + tempContent.substring(index + keywords[i].length);
                        content = content.substring(0, index) + "<span class='coveo-omnibox-hightlight-bold'>" + content.substring(index, index + keywords[i].length) + "</span>" + content.substring(index + keywords[i].length);
                    }
                }
                return content.replaceAll('</span> <span ', '</span>&nbsp;<span ');
            },
            autosuggestClickHandler(event, url, index) {
                let searchInfoObj = {};
                let inputBox = document.querySelector(".magic-box-input input");
                searchInfoObj.searchTermEntered = inputBox? inputBox.value : '';
                if(event.target.textContent === "Fact sheet"){
                    searchInfoObj.resultClicked = event.target.previousElementSibling.textContent.trim();
                    searchInfoObj.autoSuggestMediaType = "Fact Sheet";
                }else {
                    searchInfoObj.resultClicked = event.currentTarget.innerText;
                }
                searchInfoObj.autoSuggestTermSelectedPosition = index;
                searchInfoObj.searchType = url.indexOf('/search-results')> -1 ? this.searchTypeGlobal : this.searchTypeAutoSuggest;
                adobeLaunch.trackSearchConducted(searchInfoObj);
                this.logCoveoCustomEvent(event)
            },
            autosuggestKeyPressHandler(event, title, url, index) {
                let searchInfoObj = {};
                let inputBox = document.querySelector(".magic-box-input input");
                searchInfoObj.searchTermEntered = inputBox? inputBox.value : '';
                searchInfoObj.resultClicked = title;
                searchInfoObj.autoSuggestTermSelectedPosition = index;
                searchInfoObj.searchType = url.indexOf('/search-results')> -1 ? this.searchTypeGlobal : this.searchTypeAutoSuggest;
                adobeLaunch.trackSearchConducted(searchInfoObj);
                this.logCoveoCustomEvent(event)
                window.location.href= url;
            },
            logCoveoCustomEvent(event){
                //Capture UA Events for Fund Suggestions
                const mainPageRoot = document.getElementById("search-results");
                const searchBoxRoot = document.getElementById("fas-global-search");
                const root = mainPageRoot ? mainPageRoot : searchBoxRoot;
                let eventCause = 'manualSearchFundRedirect';
                let metadata = "";
                if (event.target.href){
                    if (event.target.innerHTML.toLowerCase().indexOf("fact sheet")>-1) {
                        eventCause = 'manualSearchFactSheetClick';
                        metadata = { partialQuery: event.target.searchTerm, documentUrl: event.target.documentUrl, documentTitle: event.target.documentTitle, ticker: event.target.ticker, sublinkUrl: event.target.sublinkUrl, sublinkTitle: event.target.sublinkTitle, suggestionRanking: event.target.position };
                    }else{
                        metadata = { partialQuery: event.target.searchTerm, documentUrl: event.target.documentUrl, documentTitle: event.target.documentTitle, ticker: event.target.ticker, suggestionRanking: event.target.position };
                    }
                    let customEventCause = { name: eventCause, type: 'search box' };
                    Coveo.logCustomEvent(root, customEventCause, metadata);
                    const href = this.sanitizeForXSS(event.target.href);
                    if( eventCause !== 'manualSearchFactSheetClick') window.location.href = href;
                }else{
                    let docObj = event.target.querySelector('.search-results__item');
                    if (docObj.innerHTML && docObj.innerHTML.toLowerCase().indexOf("fact sheet")>-1) {
                        eventCause = 'manualSearchFactSheetClick';
                        metadata = { partialQuery: docObj.searchTerm, documentUrl: docObj.documentUrl, documentTitle: docObj.documentTitle, ticker: docObj.ticker, sublinkUrl: docObj.sublinkUrl, sublinkTitle: docObj.sublinkTitle, suggestionRanking: docObj.position };
                    }else{
                        metadata = { partialQuery: docObj.searchTerm, documentUrl: docObj.documentUrl, documentTitle: docObj.documentTitle, ticker: docObj.ticker, suggestionRanking: docObj.position };
                    }
                    let customEventCause = { name: eventCause, type: 'search box' };
                    Coveo.logCustomEvent(root, customEventCause, metadata);
                    if( eventCause !== 'manualSearchFactSheetClick') window.location.href = this.sanitizeForXSS(docObj.href);
                }
            },
            trackAllResultsClick(searchTerm) {
                this.trackResultsClick(searchTerm);
            },
            trackResultsClick(searchTerm){
                let searchInfo = {};
                searchInfo.searchTermEntered = searchTerm;
                searchInfo.searchType = this.searchTypeGlobal;
                adobeLaunch.trackSearchConducted(searchInfo);
            },
            trackEnterOrSearchForResultsClick(searchTerm,searchType) {
                let searchInfo = {};
                searchInfo.searchTermEntered = searchTerm;
                searchInfo.searchType = searchType;
                adobeLaunch.trackSearchConducted(searchInfo);
            },
            closeAutosuggestOnClickOfoutsideBox() {
                // close suggestions box when clicking outside
                window.onclick = (e)=> {
                    if (window.magicBox) {
                        window.magicBox.clearSuggestion();
                    }
                    let mobilePanel = document.querySelector('.mobile-tab-panel');
                    if(!mobilePanel) return;
                    var customTabs = document.querySelector(".CoveoCustomTabs");
                    if (customTabs && customTabs != event.srcElement.parentElement && mobilePanel.style.visibility === 'visible'
                    && !this.isCoveoTab(e))
                        document
                            .querySelector(".CoveoCustomTabs")
                            .CoveoCustomTabs.closeMobileTabDropDown();
                }
            },
            isCoveoTab(e){
                if(e.target.className.includes('coveo-selected')){
                    return true;
                }else if(e.target.parentElement.className.includes('coveo-selected')){
                    return true;
                }else{
                    return  false;
                }
            },
            updateDefaultBehaviorofStartsWith() {
                if (!String.prototype.startsWith) {
                    Object.defineProperty(String.prototype, 'startsWith', {
                        value: function(search, rawPos) {
                            var pos = rawPos > 0 ? rawPos|0 : 0;
                            return this.substring(pos, pos + search.length) === search;
                        }
                    });
                }
            },
            loadProductResults() {
                let self = this;
                if(self.omniboxResults.length === 0) {
                    let numberOfResults = 1000;

                    let query = Coveo.IQuery = {
                        q: "",
                        cq: '@source==("FAS_Strategies")',
                        wildcards: false,
                        enableQuerySyntax: true,
                        numberOfResults: numberOfResults,
                        fieldsToInclude: ["fasinsurance","faslongname","clickableuri","fasfundnumbers","fascusip","fastickersymbolsdata","fasfactsheeturls"],
                        searchHub: self.coveoConfigs.searchHub
                    };
                    Coveo.SearchEndpoint.endpoints["default"].search(query).done(function (data) {
                        self.omniboxResults = data.results;
                        window.fasGlobalSearch.currentOmniboxResults = data.results;
                    });
                }
                //hide clear icon everytime search bar opens
                let magicBoxClear = document.querySelector('.magic-box-clear');
                if(!magicBoxClear) return;
                magicBoxClear.style.display = 'none';
                self.updateReturnIcon(null);
            },
            FindOmniboxResults(wordToSearch,numberOfResults) {
                let results = [];
                wordToSearch = wordToSearch.toLowerCase();
                let splittedWords = wordToSearch.split(" ");
                let partialTerm = splittedWords.pop();
                for (var i=0; i<this.omniboxResults.length && results.length < numberOfResults; i++	){
                    if (this.omniboxResults[i].raw.fascusip.toLowerCase().startsWith(wordToSearch) ||
                        this.omniboxResults[i].raw.fasfundnumbers.toLowerCase().startsWith(wordToSearch) ||
                        this.omniboxResults[i].raw.fastickersymbolsdata.toLowerCase().startsWith(wordToSearch)){
                        results.push(this.omniboxResults[i]);
                        continue;
                    }

                    let splittedTitle = this.omniboxResults[i].title.toLowerCase().split(' ');
                    let matchAllTerms = true;
                    for (let termid =0; termid < splittedWords.length; termid++){
                        let term = splittedWords[termid];

                        if (matchAllTerms && splittedTitle.indexOf(term) === -1)
                            matchAllTerms = false;
                    }

                    if (matchAllTerms){
                        let found = false;
                        for (let j=0;j<splittedTitle.length;j++){
                            if (splittedTitle[j].startsWith(partialTerm))
                                found = true;
                        }

                        matchAllTerms = matchAllTerms && found;
                    }

                    if (matchAllTerms)
                        results.push(this.omniboxResults[i]);
                }

                return results;
            },
            isSiteInternalOrExternal(){
                const curLoc=window.location.host;
                return  curLoc.indexOf("fasi.vanguard.com")>-1;
            },
            async buildOmniboxContent(wordToSearch, populateOmniboxObject) {
                let self = this;
                document.getElementsByClassName('coveo-magicbox-suggestions')[0].classList.remove('hide-suggestions');
                if (!self.omniboxResults || wordToSearch.length < 2) {
                    return undefined;
                }
                let termsToHighlight = wordToSearch.split(" ");
                let numberOfResults = 8;

                let promiseResolve = [numberOfResults]
                let promiseReject = [numberOfResults];
                let promise = [numberOfResults];

                for (let i=0; i<=numberOfResults; i++) {
                    promise[i] = new Promise(function(resolve, reject){
                        promiseResolve[i] = resolve;
                        promiseReject[i] = reject;
                    });
                    populateOmniboxObject.rows.push({deferred:promise[i]});
                }
                let currentOmniboxResults = self.FindOmniboxResults(wordToSearch,numberOfResults);

                    let suggestionsBox= document.querySelector('.coveo-magicbox-suggestions');
                    suggestionsBox.className += ' container ';
                    if (currentOmniboxResults.length > 0) {
                        if (suggestionsBox.className.indexOf(' hide-ml-suggestions') === -1)
                            suggestionsBox.className += ' hide-ml-suggestions ';
                    }
                    else{
                        suggestionsBox.className = suggestionsBox.className.replace(' hide-ml-suggestions','');
                    }
                    for (let i=0; i<=numberOfResults; i++) {
                        let result = currentOmniboxResults[i];
                        let index = i+1;

                        if (i >= currentOmniboxResults.length){
                            let objectToReturn = {
                                zIndex: 100, //Arbitrary number
                                element : null
                            }
                            promiseResolve[i](objectToReturn);
                            continue;
                        }
                        let oneRowOfContent = document.createElement("div");
                        oneRowOfContent.className = "coveo-omnibox-selectable magic-box-suggestion query-resul-suggestion";
                        oneRowOfContent.style.display = 'flex';
                        oneRowOfContent.style.justifyContent = 'space-between';
                        let title = result.raw.fastickersymbolsdata + ' ' + result.raw.faslongname;
                        if(result.raw.fasinsurance === "true") {
                            let fundName = result.raw.faslongname.split("-")[0];
                            title = result.raw.faslongname.replace(fundName, "VVIF ");
                        }
                        let docLink = document.createElement('a')
                        docLink.className = "search-results__item";
                        docLink.tabIndex="0";
                        docLink.href = result.raw.clickableuri.replace(/^[a-zA-Z]{5}\:\/{2}[a-zA-Z0-9_.:-]+\//,self.domainSiteUrl);
                        docLink.innerHTML = title;
                        docLink.innerHTML = self.highlight(title, termsToHighlight);
                        docLink.style.whiteSpace = 'pre-wrap';

                        docLink.searchTerm = wordToSearch;
                        docLink.documentTitle = title;
                        docLink.documentUrl = result.ClickUri;
                        docLink.position = i+1;
                        docLink.ticker = result.raw.fastickersymbolsdata;

                        docLink.addEventListener("click", (event) => {
                            self.autosuggestClickHandler(event, result.raw.clickableuri, index);
                        });
                        docLink.addEventListener("keypress", (event) => {
                            self.autosuggestClickHandler(event, result.raw.clickableuri, index);
                        });
                        Coveo.$$(oneRowOfContent).on("keyboardSelect" , function(e){
                            let fundTitle = e.target.querySelector('.search-results__item').text;
                            let url = "#";
                            if (e.target.href){
                                url = self.sanitizeForXSS(e.target.href);
                            }
                            else{
                                url = self.sanitizeForXSS(e.target.querySelector('.search-results__item').href);
                            }
                            self.autosuggestKeyPressHandler(e, fundTitle, url, index);
                        });
                        oneRowOfContent.appendChild(docLink);
                        if(result.raw.fasfactsheeturls){
                            let factSheetLink = document.createElement('a')
                            factSheetLink.className = "search-results__factsheet";
                            factSheetLink.style.color = "#96151d";
                            factSheetLink.tabIndex="0";
                            factSheetLink.href = result.raw.fasfactsheeturls;
                            factSheetLink.innerHTML = 'Fact sheet';
                            factSheetLink.target = '_blank';
                            factSheetLink.rel = "noreferrer";

                            factSheetLink.ticker = result.raw.fastickersymbolsdata;
                            factSheetLink.searchTerm = wordToSearch;
                            factSheetLink.documentTitle = title;
                            factSheetLink.documentUrl = result.ClickUri;
                            factSheetLink.sublinkTitle = "Fact sheet";
                            factSheetLink.sublinkUrl = result.raw.fasfactsheeturls;
                            factSheetLink.position = i+1;

                            let icon = document.createElement('span');
                            icon.innerHTML = '<Icon type="download" ariaLabel="download icon" class="icon icon-download"/>';
                            factSheetLink.appendChild(icon);
                            factSheetLink.addEventListener("click", (event) => {
                                self.autosuggestClickHandler(event, result.raw.fasfactsheeturls, index);
                            });
                            oneRowOfContent.appendChild(factSheetLink);
                        }
                        let objectToReturn = {
                            zIndex: 100, //Arbitrary number
                            element : oneRowOfContent,
						    text: title
                        }

                        promiseResolve[index](objectToReturn);
                    }
            },
            async renewToken(){
                let tokenResponse = await CoveoService.getCoveoSecureToken();
                return  tokenResponse.secureToken;
            },
            handleOmniBoxData(searchBarContent,self_this){
                setTimeout(function() {
                    EventBus.$emit('after-coveo-init');
                    self.inputBox = document.querySelector(".magic-box-input input");
                    self.inputBox.placeholder = searchBarContent.longText;
                    self.inputBox.addEventListener('input', self.inputBoxKeyUp, false);
                    self.inputBox.addEventListener('keyup', self.inputBoxKeyUp, false);
                    let omnibox = document.getElementsByClassName('CoveoOmnibox')[0].CoveoOmnibox;
                    window.magicBox = omnibox.magicBox;
                    let inputManager = omnibox.magicBox.inputManager;
                    let suggestionManager = omnibox.magicBox.suggestionsManager;
                    let oldKeyDown = inputManager.input.onkeydown;
                    let oldBlur = inputManager.input.onblur;
                    inputManager.magicBox.onSuggestions = (e) => {
                        omnibox.lastSuggestions = e;
                        if(e.length > 0 && e.at(e.length-1).html.indexOf('viewAllResultsLink') != -1) {
                            e.at(e.length-1).onSelect = () => {
                                let query =  e.at(e.length-1).text.replace('View all results for', '').trim();
                                query =  query.replace('<b>', '').trim();
                                query =  query.replace('</b>', '').trim();
                                const mainPageRoot = document.getElementById("search-results");
                                if(!mainPageRoot){// so that it is not triggered in search page
                                    self_this.trackAllResultsClick(query);
                                }

                                if (e.keyCode == 9) // tab
                                    window.magicBox.clearSuggestion();
                            }
                        }
                    }
                    inputManager.magicBox.magicBoxClear.element.el.onclick = () => {
                        self_this.updateReturnIcon(null);
                    }
                    suggestionManager.returnMoved= function (selected) {
                        if (selected) {
                            if (selected['suggestion']) {
                                return selected['suggestion'];
                            }
                            if (selected['no-text-suggestion']) {
                                return null;
                            }
                            if (selected instanceof HTMLElement) {
                                //Below loop is to add styling when user traverse through suggessions using arrow keys
                                omnibox.magicBox.suggestionsManager.currentSuggestions.forEach((element) => {
                                    element.dom.style.backgroundColor = 'white';
                                    if(element.dom.classList.value.indexOf('coveo-omnibox-selected') !== -1) {
                                        element.dom.style.textDecoration = "underline";
                                    } else {
                                        element.dom.style.textDecoration = "none";
                                    }
                                });
                                if (selected.innerText.startsWith('View all results')){
                                    return {
                                        text: selected.innerText.replace("View all results for", "").trim()
                                    };
                                } else if (selected.classList.contains('query-resul-suggestion') ) {
                                    return {
                                        text: selected.textContent.replace("Fact sheet", "")
                                    };
                                } else {
                                    return {
                                        text: selected.innerText
                                    };
                                }
                            }
                        }
                        return null;
                    };
                    inputManager.onblur = function () {
                        // empty on purpose
                    };
                    inputManager.keydown = function (e) {
                        let keyCode = e.keyCode;
                        let which = e.which;
                        switch (keyCode || which) {
                            case -9:
                                keyCode=40;
                                which = 40;
                                break;
                            default:
                                e.stopPropagation();
                                var currentSuggestion = inputManager.magicBox.suggestionsManager.focusedSuggestion;
                                if (keyCode == 13 && currentSuggestion && currentSuggestion.index == -1){
                                    var div2 = document.createElement("div");
                                    div2.innerText = currentSuggestion.dom.firstElementChild.href;
                                    const href2 = div2.innerHTML;
                                    window.location.href= href2;
                                    omnibox.magicBox.blur();
                                }
                                else if (inputManager.onkeydown(keyCode || which)) {

                                }
                                else {
                                    e.preventDefault();
                                }
                                break;
                        }
                    };
                    inputManager.keyup = function (e) {
                        let keyCode = e.keyCode;
                        let which = e.which;
                        if (keyCode == -9) {
                            keyCode=40;
                            which = 40;
                        }
                        switch (keyCode || which) {
                            case 37:
                            case 39:
                                inputManager.handleLeftRightArrow(e);
                                break;
                            default:
                                // if we have suggestions coming from query results, remove all ML suggestions
                                var currentSuggestions = omnibox.magicBox.suggestionsManager.currentSuggestions;
                                var hideMLSuggestions = omnibox.magicBox.suggestionsManager.element.querySelector('.hide-ml-suggestions');
                                if (hideMLSuggestions && currentSuggestions.length > 0 && currentSuggestions[0].dom){
                                    var toDelete = currentSuggestions.filter(a => a.html && a.index != -1);
                                    toDelete.forEach(function(mlSuggestion) {
                                        mlSuggestion.dom.remove();

                                    });
                                }
                                if (inputManager.onkeydown == null || inputManager.onkeyup(keyCode || which)) {
                                    inputManager.onInputChange();
                                } else {
                                    e.preventDefault();
                                }
                                break;
                        }
                    }
                    self.inputBox.removeEventListener('keydown',oldKeyDown, false);
                    self.inputBox.removeEventListener('blur',oldBlur, false);
                    self.inputBox.addEventListener('blur', self.inputBoxBlur, false);
                    let element = document.getElementById('fas-global-search');
                    element.style.removeProperty("display");
                }, 10);
           },
            handleCoveoData(){
                const mainPageRoot = document.getElementById("search-results");
                const searchBoxRoot = document.getElementById("fas-global-search");
                const root = mainPageRoot ? mainPageRoot : searchBoxRoot;
                let self = this;
                    Coveo.SearchEndpoint.configureCloudV2Endpoint(
                        self.coveoConfigs.orgId,
                        self.coveoConfigs.secureToken,
                        "https://platform.cloud.coveo.com/rest/search/",
                        {
                            renewAccessToken: self.renewToken
                        }
                    );
                    Coveo.$$(root).on("state:change:q", (e, data)=> {
                        self.inputBoxKeyUp(true);
                    });
                    EventBus.$on("load-coveo-suggestions", this.loadProductResults);
                    if (!mainPageRoot) {
                        Coveo.$$(root).on("changeAnalyticsCustomData", (e, data)=> {
                            data.originLevel1 = self.coveoConfigs.searchHub;

                                if(data.actionCause==="omniboxFromLink" ||data.actionCause==="omniboxAnalytics" ){
                                    self.isMLSuggestion=true;
                                     let searchInfo = {};
                                     searchInfo.searchTermEntered = data.metaObject.partialQuery;
                                     searchInfo.resultClicked = e.target.innerText;
                                     searchInfo.autoSuggestTermSelectedPosition = data.metaObject.suggestionRanking + 1;
                                     searchInfo.searchType = self.searchTypeGlobal;
                                     adobeLaunch.trackSearchConducted(searchInfo);
                                }
                        });
                        Coveo.$$(searchBoxRoot).on("beforeRedirect", function(e, data) {
                            window.fasGlobalSearch.cancelNextQuery = false;
                            if (!window.fasGlobalSearch.currentOmniboxResults)
                                return;

                            let query = e.srcElement.Coveostate.attributes.q.toLowerCase().trim();
                            if(!query) {
                                return data.cancel = true;
                            }
                            if (window.fasGlobalSearch.previoueQuery != query) {
                                window.fasGlobalSearch.currentOmniboxResults.forEach(async function(omniboxResult) {
                                    if (query == omniboxResult.raw.fasfundnumbers.toLowerCase() ||
                                        query == omniboxResult.raw.fascusip.toLowerCase() ||
                                        query == omniboxResult.raw.fastickersymbolsdata.toLowerCase()) {
                                        window.fasGlobalSearch.cancelNextQuery= true;
                                        //START: Send data to Coveo Analytics
                                        let customEventCause = { name: 'autoSearchFundRedirect', type: 'search box' };
                                        let metadata = { ticker: query };
                                        Coveo.logCustomEvent(root, customEventCause, metadata);
                                        //END: Send data to Coveo Analytics
                                        data.cancel = true;
                                        self.trackEnterOrSearchForResultsClick(query,self.searchTypeAutoSuggest);
                                        self.isGlobal=false;
                                        window.location.href = omniboxResult.clickUri.replace(/^[a-zA-Z]{5}\:\/{2}[a-zA-Z0-9_.:-]+\//,self.domainSiteUrl);
                                    }
                                });
                                if(self.isGlobal && !self.isMLSuggestion && !data.actionCause){
                                    self.trackEnterOrSearchForResultsClick(query,self.searchTypeGlobal);
                                }
                            }
                        });
                        Coveo.initSearchbox(searchBoxRoot, "/searchresults");
                    }
                    Coveo.$$(root).on(Coveo.InitializationEvents.afterComponentsInitialization, function(e, data) {
                        self.handleOmniBoxData(self.searchBarContent,self);
                        const query = e.srcElement.Coveostate.attributes.q.toLowerCase().trim();
                        window.fasGlobalSearch.previoueQuery = query;
                        if (!window.fasGlobalSearch.cancelNextQuery) {
                            Coveo.state(root, "t", "All Results");
                        }
                    });

                    Coveo.$$(searchBoxRoot).on('populateOmniboxSuggestions', function(e , populateOmniboxObject) {
                        // display the link to 'View all results for [query]' at the bottom of suggestion list
                        let query = populateOmniboxObject.omnibox.getText();
                        query = DOMPurify.sanitize(query);
                        query = query.replaceAll(">","&gt;");
                        query = query.replaceAll("<","&lt;");
                        let title = self.searchBarContent.all.replace("{{query}}", query);
                        let viewAllResults = '<a id="viewAllResultsLink" href="/searchresults#q='+ query +'">'+title+'</a>';
                        viewAllResults = DOMPurify.sanitize(viewAllResults);
                        populateOmniboxObject.suggestions.push(new Promise(function (resolve) {
                            resolve([
                                {
                                    index: -1,
                                    text: title,
                                    html: viewAllResults
                                }
                            ]);
                        }));
                    });
                    Coveo.$$(root).on('newQuery', function(e, data) {
                        self.inputBoxKeyUp(null);
                        // make sure suggestion box don't show
                        document.getElementsByClassName('coveo-magicbox-suggestions')[0].classList.add('hide-suggestions');
                        if(window.fasGlobalSearch.cancelNextQuery)
                            data.cancel = true;
                    });
                    Coveo.$$(root).on('populateOmnibox', function(e , populateOmniboxObject) {
                        self.inputBoxKeyUp(true);
                        if(populateOmniboxObject.rows.length === 0) {
                            self.buildOmniboxContent(populateOmniboxObject.completeQueryExpression.word, populateOmniboxObject);
                        }
                    });
            }
        },
        async mounted() {
            let self = this;
            window.fasGlobalSearch = {
                cancelNextQuery: false,
                currentOmniboxResults: [],
                previoueQuery: ""
            };
            this.domainSiteUrl = await EnvConfigService.checkSiteInternalOrExternal()+'/';
            EventBus.$on("coveo-scripts-loaded", this.handleCoveoData);
            this.closeAutosuggestOnClickOfoutsideBox();
            this.updateDefaultBehaviorofStartsWith();
            setTimeout(()=>{
                if(this.searchPage){
                    EventBus.$emit('coveo-scripts-loaded');
                    this.loadProductResults();
                    self.handleOmniBoxData(self.searchBarContent,self);
                }
                let keyUpEvent = document.querySelector(".magic-box-input input");
                if(!keyUpEvent) return;
                keyUpEvent.addEventListener('keyup',()=>{
                    self.inputBoxKeyUp(true);
                });
            },1000);
        }
    }
</script>

<style lang="scss">
    .hide-suggestions{
        display: none;
    }
    #viewAllResultsLink {
        color: #96151d;
    }
    .hide-ml-suggestions #magic-box-suggestion-1:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-2:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-3:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-4:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-5:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-6:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-7:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-8:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-9:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-10:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-11:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-12:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-13:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-14:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-15:not(.query-resul-suggestion),
    .hide-ml-suggestions #magic-box-suggestion-16:not(.query-resul-suggestion) {
        display: none; }

    #headerCoveoExternalComponents{
        width: 100%;
    }
    @media (min-width: 1326px){
        #headerCoveoExternalComponents .CoveoSearchbox .magic-box .magic-box-suggestions .coveo-magicbox-suggestions {
            margin: 24px auto !important;
        }
    }
    body > header > div.coveo-search-panel.search-panel > div.container.row > div > div > div > div.magic-box-suggestions.magic-box-hasSuggestion > div{
        margin: 0 23px;
    }
    body > header > div.coveo-search-panel.coveo-search-panel-box > div.container.row > div > div > div > div.magic-box-suggestions.magic-box-hasSuggestion > div{
        margin: 0 23px;
    }
    .magic-box .magic-box-suggestions{
        position: absolute !important;
    }
    body > header > div.coveo-search-panel.coveo-search-panel-box > div.container.row > div > div > div > div.magic-box-suggestions > .coveo-magicbox-suggestions > .coveo-omnibox-selected{
        text-decoration: underline;
        background-color: white !important;
    }
    #headerCoveoExternalComponents .CoveoSearchbox .magic-box {
        border:none;
        position: static;
        .magic-box-suggestions .coveo-magicbox-suggestions {
            border: none;
            display: block;
           // margin: 24px 23px;
            .coveo-omnibox-selectable {
                padding:16px 0;
                margin-left: 0;
                border: none;
            }
            .coveo-omnibox-selected {
                @include hover {
                    text-decoration: underline;
                    background-color: white;
                }
            }
        }
        .magic-box-suggestions .magic-box-suggestion:last-child {
            display: block !important;
        }
        .magic-box-input {
            background: top;
            .magic-box-clear {
                background: top;
                width: 18px;
                height: 34px;
                transition: visibility .01s,width .3s;
                .magic-box-icon {
                    background-image: url("/content/dam/fas/visual-asset/iconography/clear_icon.svg");
                    background-repeat: no-repeat;
                    display: inline-flex;
                    width: 16px;
                    height: 16px;
                    .magic-box-clear-svg {
                        visibility: hidden;
                    }
                }
            }
        }
    }
    #headerCoveoExternalComponents .CoveoSearchbox .CoveoSearchButton {
        border:none;
        .coveo-search-button {
            background-image: url("/content/dam/fas/visual-asset/iconography/return_icon_disabled.svg");
            background-repeat: no-repeat;
            width: 24px;
        }
        .coveo-search-button-svg {
            visibility: hidden;
        }
    }
    #headerCoveoExternalComponents .CoveoSearchbox .magic-box .magic-box-input>input {
        padding-left: 0;
        color: #232323;
        font-size: 24px;
    }
    .--small-search-open {

        .global-header {

            .coveo-search-panel-small {
                top: 90px;

                &--search {
                    top:100%;
                    position: relative;
                }

                .container {
                    height: 100%;
                }
            }
            .coveo-search-panel-box{
                display: block;
            }
            .coveo-search-panel.search-panel{
                height: 80%;
            }
        }
    }
    .coveo-search-panel-box, search-panel{
        display: none;
    }
    body.--small-search-open {
        .global-header {
            .search-panel{
                display: block;
            }
        }
    }
    body{
        .global-header {
            .search-panel-small{
                display: none;
            }
        }
    }
    /*END: Styling for small view*/

    /*START: Styling for large view*/
    .global-header {
        .coveo-search-panel {
            background: $color--almost-white;
            border: 2px solid $color--lighter-gray;
            border-width: 2px 0 1px 0;
            padding: 14px 0 14px 0;
            position: absolute;
            bottom: 2px;
            top: 100%;
            width: 100%;
            z-index: 7;

            &--search {
                position: relative;
            }

            // Contents of the panel
            a {
                color: $color--almost-black;
                display: table;
                font-size: 15px;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .global-header {
        .coveo-search-panel.coveo-search-panel-box {
            bottom: unset;
            top: unset;
        }
    }
    body > header > div.coveo-search-panel.search-panel.search-panel-large{
        display: none;
    }
    body.--search-open > header > div.coveo-search-panel.search-panel.search-panel-large{
        display: block;
        top: 97%;
        min-height: fit-content;
    }
    /*END: Styling for large view*/
    //NGA and Monolith overrides
    .global-header {

        .coveo-search-panel {

            .coveo-search-panel__input {
                height: initial;
                border: initial;
                padding-left: 0;
                padding-right: 0;

                &:focus {
                    border: initial !important; // needs !important to override app.min.css's !important declaration
                }
            }
        }
    }
    .coveo-omnibox-hightlight-bold{
        font-weight: bold;
    }
    .search-results__item{
        font-weight: 100;
    }
    .icon-download {
        margin-left: 2px;
        margin-top: 2px;
    }
    .magic-box-hasSuggestion{
        position: unset !important;
    }
    .--search-open{
        .global-header {

            .coveo-search-panel.coveo-search-panel-box {
                display: block;
            }
            .coveo-search-panel.search-panel{
                height: 60%;
            }
        }
    }
</style>